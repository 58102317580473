@tailwind base;
@tailwind components;
@tailwind utilities;
@import "../node_modules/prismjs/themes/prism-tomorrow";
@import '~react-datetime/css/react-datetime.css';
@import "assets/scss/_agGridStyleOverride.scss";

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

iframe#webpack-dev-server-client-overlay{display:none!important}

.file-item {
  border-radius: 8px;
  box-sizing: border-box !important;
  padding: 16px;
  background: rgba(#3f51b5, 0.03);
  color: #3f51b5;
}

.aggrid-height{
  height: calc(100% - 50px) !important;
}

.file-item-upload-space {
  border: 2px dashed grey;
  border-radius: 0 0 12px 12px;
  color: #3f51b5;
  min-height: 48px;

  span {
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
  }
}

.progress .progress-bar {
  border-radius: 0 !important;
}

.file-item-action {
  border-radius: 64px;
}

body {
  font-size: 13px !important;
  font-family: "Open Sans", serif;
}

.floating-left {
  right: 96px;
  z-index: 200;
  bottom: 32px;
  border-radius: 40px;
}

* {
  outline: none !important;
}

.margin-chip {
  margin-right: 4px;
  margin-bottom: 6px !important;
}

.email-application .email-app-details .email-scroll-area {
  height: 100% !important;
}

.btn-borderaux {
  position: fixed !important;
  bottom: 54px;
  right: 54px;
}

#request-batches-container {
  min-height: 250px;
  border-radius: 4px;
  padding: 8px;
  border: 4px dashed #68b4ff;
  box-sizing: border-box;
}


::-webkit-scrollbar {
  width: 8px;
  border-left: 1px solid lightgrey;
}

::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 4px;
}


.validation-batch-btn {
  padding: 4px;
  height: auto;
}

.ag-cell-focus, .ag-cell-no-focus {
  border: none !important;
}

/* This CSS is to not apply the border for the column having 'no-border' class */
.no-border.ag-cell:focus {
  border: none !important;
  outline: none;
}

.subject-item {
  list-style: none;

  & .icon-close {
    opacity: 0;
    transition: .3s;
  }

  &:hover .icon-close {
    opacity: 1;
    transition: .3s;
  }
}

#my-node {
  td {
    vertical-align: top;
  }
}

.sidebar-session {
  z-index: 100;
  right: 0;
  position: fixed;
  background: white;
  max-width: 400px;
  box-sizing: border-box;
  padding: 54px 24px 32px;
  box-shadow: -4px 0 8px .1px rgba(black, .15);
  top: 0;
  height: 100vh;
}

.ag-theme-material {
  color: black !important;
}

.img-box {
  height: 40px;
  width: 40px;
  border: 2px solid black;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 1px 8px .1px rgba(0, 0, 0, .1);

  img {
    height: 100%;
    width: 100%;
  }
}

.base-Menu-root{
  z-index: 10000;
  //background: #fff;
}

.base-Menu-listbox{
  background: white;
}