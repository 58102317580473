//@import "../../../node_modules/bootstrap/scss/functioa

//$icons-path: "~ag-grid-community/src/styles/ag-theme-material/icons/" !default;
//
//$font-family: inherit;
//$font-size: inherit;
//$font-weight: inherit;

@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-material.css";
@import "ag-grid-community/styles/ag-theme-alpine.min.css";
@import "ag-grid-community/styles/ag-theme-alpine.css";

.ag-grid-table {
  height: calc(100vh - 15rem);
  @media screen and (max-height: 800px) {
    height: 620px;
  }

  .ag-row:nth-child(2n+1) {
    //background-color: red;
  }

  .ag-header-cell {
    .ag-cell-label-container {
      .ag-header-icon.ag-filter-icon {
        display: none;
      }
    }
  }

  // style select of column header filter
  .ag-dropdown {
    padding: 0.3rem;
    background: transparent;
    border-radius: 50px;
    border: 1px solid #DEE2E6FF;
    cursor: pointer;

    .dropdown-menu {
      min-width: 3.5rem;
    }
  }

  // style select of column header filter
  .ag-filter-select {
    padding: 0.3rem;
    background: transparent;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  // style input of column header filter input
  .ag-filter-filter {
    margin-right: 10px;
    margin-left: 10px;
    padding-bottom: 0 !important;
    padding-bottom: 0 !important;
    height: 25px !important;
    margin-top: 10px;
    margin-bottom: 14px !important;
  }

  // remove extra padding of input in inline edit
  .ag-input-text-wrapper {
    input {
      padding-bottom: 0 !important;
    }
  }

  .ag-cell-inline-editing {
    height: 62px !important;
  }

  .ag-cell-inline-editting,
  .ag-popup-editor {
    padding-bottom: 12px;
  }

  .ag-grid-table-actions-right {
    @media screen and (max-width: 614px) {
      flex-grow: 1;
    }
  }

  .ag-grid-table-actions-left {
    @media screen and (max-width: 614px) and (min-width: 443px) {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
    }
  }

  // remove horizontal scroolbar from pinned columns
  .ag-horizontal-left-spacer,
  .ag-horizontal-right-spacer {
    overflow-x: auto;
  }

  // Change material theme icon to vuesax theme color
  .ag-icon-checkbox-checked {
    color: #0D6EFDFF;
  }

  .ag-icon-checkbox-checked-readonly {
    color: #6C757DFF;
  }

  .ag-icon-checkbox-indeterminate {
    color: #0D6EFDFF;
  }

  .ag-icon-checkbox-indeterminate-readonly {
    color: #6C757DFF;
  }

  // Pagination Styling
  .ag-paging-panel {
    display: flex !important;
    align-items: center;
    height: 105px;
    padding: 0;

    .ag-paging-row-summary-panel {
      display: none;
    }

    .ag-paging-page-summary-panel {
      justify-content: center;
      margin-left: 0;
      margin-top: 12px;

      [ref="lbCurrent"],
      [ref="lbTotal"] {
        color: white;
        padding: 0.7rem 0.95rem;
        border-radius: 0.5rem;
      }
    }

    .ag-paging-button {
      background-color: #DEE2E6FF;
      border-radius: 50%;
      padding: 0.5rem;
      margin: 0 0.5rem;
      color: #212529FF !important;

      .ag-icon {
        color: #212529FF !important;
        opacity: 1 !important;
      }

      &.ag-disabled {
        opacity: 0.5 !important;
      }
    }

    span[ref="lbCurrent"] {
      background-color: #0D6EFDFF;
    }

    span[ref="lbTotal"] {
      background-color: #DEE2E6FF;
      color: #212529FF !important;
    }
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #DEE2E6FF;
    border-radius: 20px;
  }

  ::-webkit-scrollbar-track {
    background: #FFFFFFFF;
    border-radius: 20px;
  }
}

@media screen and (max-width: 576px) {
  .agGrid-card {
    .card-body {
      padding-bottom: 4rem !important;
    }
  }
  .ag-grid-table {
    .ag-paging-panel {
      [ref="btFirst"],
      [ref="btLast"] {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 430px) {
  .agGrid-card {
    .card-body {
      padding-bottom: 6.5rem !important;
    }
  }
  .ag-paging-panel {
    [ref="lbCurrent"],
    [ref="lbTotal"] {
      margin: 0 5px !important;
    }

    .ag-paging-button {
      margin: 0 0.3rem !important;
    }
  }
  .export-btn {
    margin-top: 0.5rem;
  }
}
